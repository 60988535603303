import React, { useState, useEffect } from "react";
import Header from '../../components/Header/index'
import "./index.scss";
import { useSelector, useDispatch } from 'react-redux'
import Table from '../../components/Table/index'
import List from '@mui/material/List';
import { getQuotes } from "../../actions/quote";
import { quotes } from "../../redux/actions";

const Quote = (props) => {
  const dispatch = useDispatch();

  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  const side_navi = useSelector((state) => state.SideNaviBarReducer.side_bar_action);
  const quote_list = useSelector((state) => state.QuoteReducer.quote_list);
  const [reload, setReload] = React.useState(false);
  const [quoteList, setQuoteList] = React.useState(quote_list);

  const resize =()=> {
    setInnerWidth(window.innerWidth)
  }
  
  useEffect(() => {
    window.addEventListener("resize", resize)
    refreshPage()
  }, []);

  const searchText = (search_text) =>{
    let text = search_text.toLowerCase()
    if(text && text.length>0){
      const filtered_todo = quoteList.to_do_list.filter((el) => {
        return el.id.toLowerCase().includes(text) || ((el.first_name+el.last_name).toLowerCase().includes(text) || (el.first_name+" "+el.last_name).toLowerCase().includes(text))
      })
      const filtered_review = quoteList.review_list.filter((el) => {
        return el.id.toLowerCase().includes(text) || ((el.first_name+el.last_name).toLowerCase().includes(text) || (el.first_name+" "+el.last_name).toLowerCase().includes(text))
      })
      const filtered_archive = quoteList.archive_list.filter((el) => {
        return el.id.toLowerCase().includes(text) || ((el.first_name+el.last_name).toLowerCase().includes(text) || (el.first_name+" "+el.last_name).toLowerCase().includes(text))
      })
      let filtered_list = {to_do_list:filtered_todo,review_list:filtered_review,archive_list:filtered_archive}
      dispatch(quotes(filtered_list))
    }else{
      dispatch(quotes(quoteList))
    }
    
  }

  const refreshPage = () =>{
    dispatch(getQuotes())
  }

  return (
    <div className="quote-main" >
      <Header title={"Quotes"} search={true} searchText={searchText}/>
      <List style={{maxHeight: '100%', overflow: 'auto'}} >
      <div className="quote-header" style={{width:side_navi? innerWidth-240:innerWidth-55 , marginLeft:side_navi? "240px":"55px"}}>
        {
          quote_list && quote_list.to_do_list && quote_list.to_do_list.length > 0 ?
          <div className="to-do-list">
            <h3>{"To do ["+quote_list.to_do_list.length+"]"}</h3>
            <Table list={1}/>
          </div>
          :null
        }
        {
          quote_list && quote_list.review_list && quote_list.review_list.length > 0 ?
          <div className="to-do-list">
            <h3>{"With patient ["+quote_list.review_list.length+"]"}</h3>
            <Table list={2} refresh={refreshPage}/>
          </div>
          :null
        }
        {
          quote_list && quote_list.archive_list && quote_list.archive_list.length > 0 ?
          <div className="to-do-list">
            <h3>{"Archived ["+quote_list.archive_list.length+"]"}</h3>
            <Table list={3}/>
          </div>
          :null
        }
      </div>
      </List>
      
    </div>
  );
}

export default Quote;
