import React from 'react'
import { TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';

const TextInput = (props) => {
    return (
        <TextField 
            {...props}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {props.icon}
                    </InputAdornment>
                ),
            }}
            id="standard-basic"
            label={props.label}
            variant="standard" 
            sx={{
                width: 250
            }}
        />
    )
}

export default TextInput;