import { AllRoutes, LoginRoutes } from "../routes/allRoutes";
import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";

export const APPS = [
  {
    subdomain: "www",
    app: AllRoutes,
    main: true,
  },
  {
    subdomain: "test",
    app: LoginRoutes,
    main: true,
  },
];


export const navLinks = [
  {
    id: "patient",
    title: "Patient",
  },
  {
    id: "dentist",
    title: "Dentist",
  },
  {
    id: "product",
    title: "Product",
  }
];
