const initialState = {
  side_bar_action:null
};

function SideNaviBarReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "sideNaviBar/present":
      return {
        ...state,
        side_bar_action: payload,
      };
    default:
      return state;
  }
}

export default SideNaviBarReducer;
