import { combineReducers } from "redux";
import SideNaviBarReducer from "./sideNaviBarReducer";
import QuoteReducer from "./quoteReducer";
import AuthReducer from "./authReducer";


const rootReducer = combineReducers({
  SideNaviBarReducer,
  QuoteReducer,
  AuthReducer,
});

export default rootReducer;
