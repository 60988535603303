import React, { useState } from "react";
import logo from '../../logo.svg';
import Header from '../../components/Header/index'
import "./index.scss";

// import LoginForm from "../../components/auth/LoginForm";
// import RegisterForm from "../../components/auth/RegisterForm";
// import ForgotForm from "../../components/auth/ForgotForm";
// import SubscriptionForm from "../../components/auth/SubscriptionForm";

function Setting() {
  return (
    <div className="setting-main">
      <Header title={"Setting"}/>
    </div>
  );
}

export default Setting;
