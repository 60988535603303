import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

const DatePickerField = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']} >
        <DatePicker
          format={"DD/MM/YYYY"}
          label={props.label}
          value={props.date_value ? dayjs(props.date_value): dayjs(new Date())}
          onChange={(newValue) => {
            props.date({action:props.action,date:newValue})
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default DatePickerField;