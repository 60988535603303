export const sideNaviBarPresent = (action) => ({
  type: "sideNaviBar/present",
  payload: action
});

//quote
export const quotes = (action) => ({
  type: "quotes/list",
  payload: action
});

export const quotesDetails = (action) => ({
  type: "quotes/details",
  payload: action
});

export const quoteAlert = (action) => ({
  type: "quotes/alert",
  payload: action
});

//auth
export const companyPortal = (action) => ({
  type: "auth/portal",
  payload: action
});
