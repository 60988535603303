const initialState = {
  quote_list:null,
  quote_details:null,
  quote_alert:false
};

function QuoteReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "quotes/list":
      return {
        ...state,
        quote_list: payload,
      };
    case "quotes/details":
      return {
        ...state,
        quote_details: payload,
      };
    case "quotes/alert":
      return {
        ...state,
        quote_alert: payload,
      };
    default:
      return state;
  }
}

export default QuoteReducer;
