import _ from "lodash";
import {app, auth, db} from '../firebase';
import { query, doc, getDocs, onSnapshot, collection, updateDoc, deleteDoc} from "firebase/firestore";
import { quotes, quoteAlert, companyPortal } from "../redux/actions";
import moment from "moment";

export const refreshQuote = () => {
  return async dispatch => {
    let todoArray = []
    let reviewArray = []
    let archiveArray = []

    const q = query(collection(db, "abc_dental_care", "quote_request","quote_list"));
    onSnapshot(q, (querySnapshot) => {
      // const data = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        if(data.suitability || (data.capture_outcome && data.capture_outcome.archive_reason)){
          archiveArray.push({...data,id:doc.id,created_on:data.created_on})
        }else{
          if(data.status==="otp"){
            todoArray.push({...data,id:doc.id,created_on:data.created_on})
          }else if(data.status==="review"){
            reviewArray.push({...data,id:doc.id,created_on:data.created_on})
          }
        }
      });
    });

    let list = {
      to_do_list: todoArray,
      review_list: reviewArray,
      archive_list: archiveArray
    }
    dispatch(quotes(list))
  }
};

export const getQuotes = () => {
  return async dispatch => {
    let portal = await localStorage.getItem("portal")
    const q = query(collection(db, "abc_dental_care", "quote_request","quote_list"));
    return onSnapshot(q, (querySnapshot) => {
      let todoArray = []
      let reviewArray = []
      let archiveArray = []
      querySnapshot.forEach((doc) => {
        let data = doc.data()
        if(data.suitability || data.status==="Archived" || (data.capture_outcome && data.capture_outcome.archive_reason)){
          archiveArray.push({...data,id:doc.id,created_on:data.created_on})
        }else{
          if(data.status==="New"||data.status==="In Progress"||data.status==="Reopen"){
            todoArray.push({...data,id:doc.id,created_on:data.created_on})
          }else if(data.status==="Quote Sent"|| data.status==="Booked"){
            reviewArray.push({...data,id:doc.id,created_on:data.created_on})
          }
        }
      });
      let list = {
        to_do_list: todoArray,
        review_list: reviewArray,
        archive_list: archiveArray
      }
      dispatch(quotes(list))
      dispatch(companyPortal(portal))
  
      return list
    });

    // let portal = await localStorage.getItem("portal")
    // const querySnapshot = await getDocs(collection(db, "abc_dental_care", "quote_request","quote_list"));
    // let todoArray = []
    // let reviewArray = []
    // let archiveArray = []
    // querySnapshot.forEach((doc) => {
    //   let data = doc.data()
    //   if(data.suitability || data.status==="Archived" || (data.capture_outcome && data.capture_outcome.archive_reason)){
    //     archiveArray.push({...data,id:doc.id,created_on:data.created_on})
    //   }else{
    //     if(data.status==="New"||data.status==="In Progress"||data.status==="Reopen"){
    //       todoArray.push({...data,id:doc.id,created_on:data.created_on})
    //     }else if(data.status==="Quote Sent"|| data.status==="Booked"){
    //       reviewArray.push({...data,id:doc.id,created_on:data.created_on})
    //     }
    //   }
    // });
    // let list = {
    //   to_do_list: todoArray,
    //   review_list: reviewArray,
    //   archive_list: archiveArray
    // }
    // dispatch(quotes(list))
    // dispatch(companyPortal(portal))

    // return list
  }
};

export const updateQuote = (data,status) => {
  return async dispatch => {
    let updateData = status ? {...data,status:status}: data
    const updateRef = doc(db, "abc_dental_care", "quote_request","quote_list",data.id);
    await updateDoc(updateRef, updateData);
    let quotes = await dispatch(getQuotes())
    if(quotes){
      return true
    }
  }
};

export const deleteQuote = (id) => {
  return async dispatch => {
    await deleteDoc(doc(db, "abc_dental_care", "quote_request","quote_list",id));
    dispatch(getQuotes())
  }
};

export const quoteChangesAlert = (action) => {
  return async dispatch => {
    dispatch(quoteAlert(action))
  }
};

export const firebasePushNotification = (value) => {
  return async dispatch => {
    const myHeaders = {
      'Content-Type': "application/json",
      'Authorization': "key=AAAAQ1A0jts:APA91bEmnV3KffXSRGLf7KN-KsdkK9F04ZlRr3srnSq5ffLmqHG2zCggHAlulRxRenjHG2d_-rUzhQ9Y1JoUtU9n-Q1mlUroSFnah4cLKlpy8ES4pjbdSD7XLGLRJXncbZKDf_MSyz1Q"
    };
    let data = JSON.stringify(
      {
        "to": value.firebase_token ,
        "notification": {
          "title": "Hi "+ value.first_name + " " + value.last_name,
          "body": "Test",
          "mutable_content": true
        }
      }
    )
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
    };
    return fetch("https://fcm.googleapis.com/fcm/send", requestOptions)
    .then(response => response.text())
    .then( async(result) => {
      return JSON.parse(result)
    }).catch(error => {
      return error
    });
  }
};


