// import { Layout } from "antd";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Landing from "../modules/landing/index";
import Auth from "../modules/auth/index";
import Login from "../modules/login/index";
import Dashboard from "../modules/dashboard/index";
import Patient from "../modules/patient/index";
import Quote from "../modules/quote/index";
import Quote_detail from "../modules/quote_detail/index";
import Setting from "../modules/setting/index";
import Layout from '../layout/index'
import Login_layout from '../login_layout/index'


const AllRoutes = () => {
  const [token, setToken] = useState(false);

  useEffect(async() => {
  // localStorage.setItem("token", null);
    setToken(await localStorage.getItem("token"))
  }, []);

  return (
    <Router>
      {
        JSON.parse(token) ?
        <Login_layout>
          <Switch>
            <Route exact path="/:path?/dashboard"> <Dashboard /> </Route>
            <Route exact path="/:path?/quote"> <Quote /> </Route>
            <Route exact path="/:path?/quote/:path?"> <Quote_detail /> </Route>
            <Route exact path="/:path?/patient"> <Patient /> </Route>
            <Route exact path="/:path?/setting"> <Setting /> </Route>
            
          </Switch>
        </Login_layout>
        :
        <Layout>
          <Switch>
            <Route exact path="/"> <Landing /> </Route>
            <Route exact path="/auth"> <Auth /> </Route>
            <Route exact path={"/:path?/auth"}> <Login /> </Route>
          </Switch>
        </Layout>
      }
      
    </Router>
  );
};

const LoginRoutes = () => {
  return (
    <Router>
      <Landing />
    </Router>
  );
};

export { AllRoutes, LoginRoutes };
