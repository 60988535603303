import _ from "lodash";
import { companyPortal } from "../redux/actions";
import moment from "moment";
import {app, auth, db} from '../firebase';
import {  signInWithEmailAndPassword, sendPasswordResetEmail  } from 'firebase/auth';


export const portalAction = (action) => {
  return async dispatch => {
    dispatch(companyPortal(action))
  }
};

export const resetPass = (email) =>{
  return async dispatch => {
    return sendPasswordResetEmail(auth, email)
    .then(() => {
      return true
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return {msg:errorMessage}
    });
  }
}

export const signIn = (email, password) => {
  return async dispatch => {
    return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("user", JSON.stringify(user));
        return user.accessToken
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return {msg:errorMessage}
    });
  }
};


