import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { logo, logo_icon, dashboard, quote, patient } from "../../assets/index";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { sideNaviBarPresent } from "../../redux/actions";


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideNaviBar() {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch()
  const [portal, setPortal] = useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  
  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleDrawerOpen = () => {
      setOpen(true);
      dispatch(sideNaviBarPresent(true))
  };

  const handleDrawerClose = () => {
      setOpen(false);
      dispatch(sideNaviBarPresent(false))
  };

  useEffect(async() => {
    setPortal(await localStorage.getItem("portal"))
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
        <Drawer 
            variant="permanent" 
            open={open} 
            PaperProps={{
                sx: {
                    backgroundColor: "#1DCD8D",
                    color: "white",
                }
            }}
        >
        <DrawerHeader>
          <img
            onClick={open ? handleDrawerClose:handleDrawerOpen}
            alt="logo"
            src={open ? logo:logo_icon}
            style={{width:open ? "200px":"26px",height:open ? "35px":"26px", marginRight:open ? 15:10}}
          />
        </DrawerHeader>
        <List>
          {['Dashboard', 'Quotes', 'Patients','Setting'].map((text, index) => (
            <ListItem 
              key={text} 
              disablePadding 
              sx={{ 
                display: 'block' ,
                '&& .Mui-selected': {
                  borderRight: index ===3 ?3:4,
                  borderColor: '#FFDE59',
                  width: open ? index ===3 ? 239:240: index ===3 ? 64:65
                  
                },
                position: index ===3 ?"fixed":null, 
                bottom: index ===3 ? 0:null, 
                paddingBottom: index ===3 ? 0:0
              }}
            >
              <ListItemButton
                selected={selectedIndex === index}
                onClick={() => {handleListItemClick(index); setPage(index)}}
                sx={{
                  minHeight: 55,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: !open ? 'center':null,
                  }}
                >
                  <img
                    src={index==0 ? dashboard:index==1 ? quote:index==2 ? patient:index ===3 ? patient:null }
                    style={{width:"26px",height:index==0 ?"20px":(index==2 || index==3) ?"26px":"35px"}}
                  />
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {
        page===0 ?
        history.push("/"+portal+"/dashboard")
        :page===1 ?
        history.push("/"+portal+"/quote")
        :page===2 ?
        history.push("/"+portal+"/patient")
        :page===3 ?
        history.push("/"+portal+"/setting")
        :null
      }
    </Box>
  );
}