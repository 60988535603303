import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HeaderMenu from '../../components/HeaderMenu/index'
import Header from '../../components/Header/index'
import ProgressStep from '../../components/ProgressStep/index'
import DatePickerField from '../../components/DatePicker/index'
import "./index.scss";
import { useSelector } from 'react-redux'
import DropdownField from '../../components/Dropdown/index'
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';
import { quotesDetails } from "../../redux/actions";
import QuoteModal from "../../components/QuoteModal/index";
import { useDispatch } from 'react-redux'
import moment from "moment";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { logo, logo_icon, dashboard, quote, patient } from "../../assets/index";
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

const reference_photos = [
  require("../../assets/web/img/reference1.png"),
  require("../../assets/web/img/reference2.png"),
  require("../../assets/web/img/reference3.png"),
  require("../../assets/web/img/reference4.png"),
  require("../../assets/web/img/reference5.png")
]

const Quote_detail = (props) => {

  const history = useHistory();
  const dispatch = useDispatch()
  const portal = useSelector((state) => state.AuthReducer.portal);

  const [detailFromLocalStorage, setDetailFromLocalStorage] = React.useState(null);
  const [checkedItem, setCheckedItem] = React.useState(null);
  const [referencePhotos, setReferencePhotos] = React.useState(false);
  const [data, setData] = React.useState(history.location.data ? history.location.data:null);
  const [status, setStatus] = React.useState(history.location.status ? history.location.status:null);  

  const [quoteDetails, setQuoteDetails] = React.useState(
    {
      ...data,
      access_suitability: false,
      plan:{
        plan_description:null,
        treatment_description: null,
        treatment_includes: null
      },
      indicative:{
        indicative_price:null,
        expiry_date:null
      },
      suitability:false,
      contact_patient:false
    }
  );

  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
  const side_navi = useSelector((state) => state.SideNaviBarReducer.side_bar_action);

  const [photosCheck, setPhotosCheck] = React.useState(history.location.data && history.location.data.photo_check ? true:false);
  const [accessCheck, setAccessCheck] = React.useState(history.location.data && history.location.data.access_suitability ? true:false);
  const [planCheck, setPlanCheck] = React.useState(history.location.data && history.location.data.plan ? true:false);
  const [publishCheck, setPublishCheck] = React.useState(false);
  const [suitabilityCheck, setSuitabilityCheck] = React.useState(history.location.data && history.location.data.suitability ? true:false);

  const [captureOutcome, setCaptureOutcome] = React.useState(history.location.data && history.location.data.capture_outcome ? true:false);
  const [contactPatient, setContactPatient] = React.useState(history.location.data && history.location.data.contact_patient ? true:false);


  const [updatedDetail, setUpdatedDetail] = React.useState(false);
  const [photoViewDisplay, setPhotoViewDisplay] = React.useState(false);
  const [activeStatus, setActiveStatus] = React.useState(
    status===1 ? 
      photosCheck && accessCheck ? 
      1
      :0
    :status===2 ? 
      quoteDetails.status==="Booked" ? 
      3
      :4
    :0
  );


  const resize =()=> {
    setInnerWidth(window.innerWidth)
    setInnerHeight(window.innerHeight)
  }

  const checkInfo =(info)=>{
    setPhotosCheck(info.photo_check ? true:false)
    setAccessCheck(info.access_suitability ? true:false)
    setPlanCheck(info.plan ? true:false)
    setSuitabilityCheck(info.suitability ? true:false)
    setCaptureOutcome(info.capture_outcome ? true:false)
    setContactPatient(info.contact_patient ? true:false)
  }

  useEffect(() => {
    window.addEventListener("resize", resize)
    updateInfo(data)
  }, []);


  const updateInfo =(info,action)=>{
    // if(JSON.stringify(info)!==JSON.stringify(data)){
    //   dispatch(quoteChangesAlert(true))
    // }else{
    //   dispatch(quoteChangesAlert(false))
    // }
    setQuoteDetails(info)
    dispatch(quotesDetails(info))
    if(action){
      setUpdatedDetail({action:true,type:action})
    }
  }

  const checkbox = (label,value,checked) => {
    return (
      <div>
        <FormGroup style={{marginTop:20}}>
          <FormControlLabel 
            control={
              <Checkbox 
                size={'medium'}
                sx={{
                    color: "grey",
                    '&.Mui-checked': {
                    color: "#1DCD8D",
                    }
                }}
              />
            } 
            checked={checked}
            label={label} 
            onChange={(event)=>handleChange(value,event)}
          />
        </FormGroup>
      </div>
    );
  }

  // const getDetailsFromLocalStorage = async() =>{
    // localStorage.removeItem("quote_details")
    // let localDetail = await localStorage.getItem("quote_details")
    // if(localDetail){
    //   setDetailFromLocalStorage( JSON.parse(localDetail) )
    //   let findItem = JSON.parse(localDetail).filter(res=>{
    //     return res.id===data.id
    //   })
    //   if(findItem && findItem.length>0){
    //     setQuoteDetails(...findItem)
    //   }
    // }
  // }

  // const updateLocalStorage = async(detail) =>{
    // setUpdatedDetail(true)
    // localStorage.setItem("quote_details",detail)
    // let findItem
    // if(detailFromLocalStorage){
    //   findItem = detailFromLocalStorage.filter(res=>{
    //     return res.id!=detail.id
    //   })
    // }
    // localStorage.setItem("quote_details",JSON.stringify(findItem ? [...findItem ,detail]:[detail]))
    // setDetailFromLocalStorage(findItem ? [...findItem ,detail]:[detail])
    // getDetailsFromLocalStorage()
  // }

  const handleChange = (value,event) => {
    setCheckedItem({item:value,check:event.target.checked})
    if(value=="photos"){
      updateInfo({...quoteDetails,photo_check:event.target.checked},"update")
      setPhotosCheck(event.target.checked)
    }else if(value=="access"){
      updateInfo({...quoteDetails,access_suitability:event.target.checked},"update")
      setAccessCheck(event.target.checked)
    }else if(value=="plan"){
      setPlanCheck(event.target.checked)
    }else if(value=="publish"){
      setPublishCheck(event.target.checked)
    }else if(value=="suitability"){
      setSuitabilityCheck(event.target.checked)
    }else if(value=="contact"){
      updateInfo({...quoteDetails,contact_patient:event.target.checked},"update")
      setContactPatient(event.target.checked)
    }else if(value=="outcome"){
      setCaptureOutcome(event.target.checked)
      if(!event.target.checked){
        updateInfo({...quoteDetails,capture_outcome:event.target.checked},"update")
      }
    }
  };

  const handleIndicativeDateChange = (data) => {
    if(data.action==="expiry_date"){
      updateInfo({...quoteDetails,indicative:{...quoteDetails.indicative, expiry_date:data.date.toISOString()}})
    }else{
      updateInfo({...quoteDetails,capture_outcome:{...quoteDetails.capture_outcome, date_booked:data.date.toISOString()}})
    }
  };

  const publishConfirm = () =>{
    updateInfo(quoteDetails,"publish")
  }

  const saveAction = () =>{
    updateInfo(quoteDetails,"update")
  }

  const publishCancel = () =>{
    // updateInfo({...quoteDetails,publish:false})
  }

  const notSuitPublish = () =>{
    updateInfo({...quoteDetails,suitability:true},"notSuit")
  }

  const notSuitCancel = () =>{
    updateInfo({...quoteDetails,suitability:false})
  }

  const modalShow = (data) =>{
    setUpdatedDetail(data.action)
    if(!data.check){
      if(data.type==="publish" || data.type==="notSuit"){
        history.push("/"+portal+"/quote")
      }
    }else{
      if(data.check.item=="photos"){
        updateInfo({...quoteDetails,photo_check:data.check.check})
        setPhotosCheck(data.check.check)
      }else if(data.check.item=="access"){
        updateInfo({...quoteDetails,access_suitability:data.check.check})
        setAccessCheck(data.check.check)
      }else if(data.check.item=="contact"){
        updateInfo({...quoteDetails,contact_patient:data.check.check})
        setContactPatient(data.check.check)
      }else if(data.check.item=="outcome"){
        setCaptureOutcome(data.check.check)
      }
    }
  }

  const saveOutcomeAction = (value) =>{
    setCheckedItem({item:value,check:false})
    updateInfo(quoteDetails,"publish")
  }

  const handleArchiveReason = (data) => {
    updateInfo({...quoteDetails,capture_outcome:{...quoteDetails.capture_outcome, archive_reason:data}})
  };

  const patientView = () =>{
    return(
      <Grid container justifyContent="flex-end">
        <Grid 
          item xs={7} 
          // sx={{borderRight: '1px solid grey'}}
        >
          {checkbox("Contact patient","contact",contactPatient)}
          {checkbox("Capture outcome","outcome",captureOutcome)}
          {
            captureOutcome ?
            <>
              <div style={{marginLeft:30}}>
                <div style={{width: 200}}>
                  <DatePickerField label="Date booked" date={handleIndicativeDateChange} date_value={quoteDetails.capture_outcome ? moment(quoteDetails.capture_outcome.date_booked).format("YYYY-MM-DD"):null} action={"date_booked"}/>
                </div>
                <div>
                  <TextField 
                    style={{marginRight:10, marginTop:15, width:200}} 
                    id="outlined-basic" 
                    label="Time" 
                    variant="outlined" 
                    value={quoteDetails.capture_outcome ? quoteDetails.capture_outcome.time:null}
                    onChange={(event)=>{
                      updateInfo({...quoteDetails,capture_outcome:{...quoteDetails.capture_outcome, time:event.target.value}})
                    }}
                  />
                </div>
                <div>
                  <TextField 
                    style={{marginRight:10, marginTop:15, width:200}} 
                    id="outlined-basic" 
                    label="Location" 
                    variant="outlined" 
                    value={quoteDetails.capture_outcome ? quoteDetails.capture_outcome.location:null}
                    onChange={(event)=>{
                      updateInfo({...quoteDetails,capture_outcome:{...quoteDetails.capture_outcome, location:event.target.value}})
                    }}
                  />
                </div>
                <p>Or</p>
                <p>Archive reason</p>
                <DropdownField list={handleArchiveReason} value={quoteDetails.capture_outcome ? quoteDetails.capture_outcome.archive_reason:null}/>
              </div>
              <div className='quote-detail-row-container'>
                <div onClick={()=>saveOutcomeAction("outcome")} style={{backgroundColor:"#F94D85", marginTop:10}} className="quote-detail-confirm-button">
                  <a>Save</a>
                </div>
              </div>
            </>
            :null
          }
          
        </Grid>
        <Grid item xs={5} style={{marginTop:10, paddingLeft:30}}>
          {/* <h4>Patient's Activities</h4>
          <p style={{fontSize:12, marginBottom:-10}}>Push Notifcation</p>
          <p>Allow</p>
          <p style={{fontSize:12, marginBottom:-10}}>When did patient read our quote?</p>
          <p>Not yet</p>
          <p style={{fontSize:12, marginBottom:-10}}>Booking date time</p>
          <p>Not yet</p> */}
        </Grid>
      </Grid>
    )
  }

  const todoView = () =>{
    return(
      <>
        {activeStatus==0 ? checkbox("Check patient photos","photos",photosCheck):null}
        {
          activeStatus==0 && photosCheck ?
          <>
            <div style={{marginLeft:30,marginTop:-10}}>
              <a style={{ fontSize:15, borderBottom: '1.5px solid'}} onClick={()=>{setPhotoViewDisplay(true)}} >See photos</a>
            </div>
            {
              data.zendesk_ticket && data.zendesk_ticket.length>0 ?
              <div style={{marginLeft:30, marginBottom:20}}>
                <a style={{marginTop:-10, fontSize:15, color:"black"}} href={"https://smileengine.zendesk.com/agent/tickets/"+data.zendesk_ticket}>Quotation ticket {data.zendesk_ticket} </a>
              </div>
              :
              null
            }
          </>
          :null
        }
        { activeStatus==0 ? checkbox("Assess suitability","access",accessCheck):null }
        { activeStatus==1 ? checkbox("Fill in our offer description","plan",planCheck):null}
        {
          activeStatus==1 && planCheck ?
          <div>
            <div style={{marginLeft:30, marginRight:10,marginTop:-20}}>
              <p style={{ fontSize:15}}>Below is from our template.  Please update if needed.  This is what patient will see in their mobile app:</p>
              
              <TextField
                id="outlined-multiline-static"
                label="Plan description"
                multiline
                rows={4}
                fullWidth
                style={{marginTop:10}}
                value={quoteDetails.plan ? quoteDetails.plan.plan_description:null}
                onChange={(event)=>{
                  updateInfo({...quoteDetails,plan:{...quoteDetails.plan,plan_description:event.target.value}})
                }}
              />
              <TextField
                id="outlined-multiline-static"
                label="Treatment includes"
                multiline
                rows={4}
                fullWidth
                style={{marginTop:20}}
                value={quoteDetails.plan ? quoteDetails.plan.treatment_includes:null}
                onChange={(event)=>{
                  updateInfo({...quoteDetails,plan:{...quoteDetails.plan,treatment_includes:event.target.value}})
                }}
              />
              <TextField
                id="outlined-multiline-static"
                label="Treatment description"
                multiline
                rows={4}
                onChange={(event)=>{
                  updateInfo({...quoteDetails,plan:{...quoteDetails.plan,treatment_description:event.target.value}})
                }}
                fullWidth
                style={{marginTop:20}}
                value={quoteDetails.plan ? quoteDetails.plan.treatment_description:null}
              />
              
              
            </div>
            <div className='quote-detail-row-container'>
              <TextField style={{marginRight:10, marginTop:8}} id="outlined-basic" label="Price" variant="outlined" 
                value={quoteDetails.indicative ? quoteDetails.indicative.indicative_price:null}
                onChange={(event)=>{
                  updateInfo({...quoteDetails,indicative:{...quoteDetails.indicative,indicative_price:event.target.value}})
                }}
              />
              <DatePickerField label="Expiry date" date={handleIndicativeDateChange} date_value={quoteDetails.indicative ? moment(quoteDetails.indicative.expiry_date).format("YYYY-MM-DD"):null} action={"expiry_date"}/>
            </div>
            <div className='quote-detail-row-container'>
              <div onClick={()=>saveAction()} style={{backgroundColor:"#F94D85", marginTop:10}} className="quote-detail-confirm-button">
                <a>Save</a>
              </div>
            </div>
          </div>
          :null
        }
        { activeStatus==1 ? checkbox("Publish quote to patient mobile app","publish",publishCheck):null}
        {
          activeStatus==1 && publishCheck ?
          <div className='quote-detail-row-container'>
            <div onClick={()=>publishConfirm()} style={{backgroundColor:"#F94D85"}} className="quote-detail-confirm-button">
              <a>Publish</a>
            </div>
            <div onClick={()=>publishCancel()} style={{backgroundColor:"#878B8B"}} className="quote-detail-cancel-button">
              <a>Cancel</a>
            </div>
          </div>
          :null
        }
        {activeStatus==1 ? checkbox("Patient not suitable","suitability",suitabilityCheck):null}
        {
          activeStatus==1 && suitabilityCheck ?
          <div className='quote-detail-row-container'>
            <div onClick={()=>notSuitPublish()} style={{backgroundColor:"red"}} className="quote-detail-confirm-button">
              <a>Confirm</a>
            </div>
            <div onClick={()=>notSuitCancel()} style={{backgroundColor:"#878B8B"}} className="quote-detail-cancel-button">
              <a>Cancel</a>
            </div>
          </div>
          :null
        }
      </>
    )
  }

  const checkActiveStatus = (value) =>{
    // if(status===2){
    //   if(quoteDetails.status==="Booked"){
    //     setActiveStatus(3)
    //   }else{
    //     setActiveStatus(4)
    //   }
    // }else{
    //   setActiveStatus(value)
    // }
    setActiveStatus(value)

  }

  const detailView = () =>{
    return(
      <div style={{width:side_navi? innerWidth-240:innerWidth-55 , marginLeft:side_navi? "240px":"55px"}}>
        <Grid  container alignItems='center' justifyContent='center'>
          <Grid item xs={3} style={{marginTop:30}}>
            <Paper
              sx={{
              p:2,
              gap: 2,
              borderRadius:2,
              height: innerHeight/1.3,
              width: "100%",
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <div style={{marginLeft:20}}>
                <p style={{fontSize:14,color:"grey"}}>Created on</p>
                <p>{moment(data.created_on).format("DD MMM YYYY")}</p>
                <h3>Progress</h3>
                <div style={{marginLeft:20}}>
                  <ProgressStep 
                    active={ activeStatus }
                    status={checkActiveStatus}
                    status_id={status}
                    quote={history.location.quote_index}
                  />
                </div>
              </div>
              
            </Paper>
          </Grid>

          <Grid item xs={8} style={{marginTop:30, marginLeft:20}}>
            <Paper
              sx={{
              p:1,
              borderRadius:2,
              height: 100,
              width: "100%",
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <Grid container justifyContent="flex-end" style={{marginLeft:20}}>
                <Grid item xs={9}>
                  <p>Name</p>
                  <h4 style={{marginTop:-10}}>{data.first_name+" "+data.last_name}</h4>
                </Grid>
                <Grid item xs={3}>
                  <p>Show contact info</p>
                </Grid>
              </Grid>
            </Paper>

            <Paper
              style={{marginTop:20}}
              sx={{
              p:2,
              borderRadius:2,
              height: (innerHeight/1.3)-100-20,
              width: "100%",
              backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <List style={{maxHeight: '100%', overflow: 'auto', marginLeft:20, marginTop:-10}}>
                <h3>Action</h3>
                { status===2 ? patientView() :todoView() }
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>   
    )
  }

  const photoView = () =>{
    return(
      <div style={{width:side_navi? innerWidth-240:innerWidth-55 , marginLeft:side_navi? "240px":"55px"}}>
        <Grid  container alignItems='center' justifyContent='center'>
          <Grid item xs={11} style={{marginTop:30, marginLeft:20}}>
            <Paper
              style={{marginTop:20}}
              sx={{
              p:2,
              borderRadius:2,
              height: (innerHeight/1.3),
              width: "100%",
              backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <div style={{display:"flex", justifyContent:"center",width:"100%"}}>
                <div style={{width:"25%",display:"flex", alignItems:"center"}}>
                  <div className="quote-detail-back" onClick={()=>{setPhotoViewDisplay(false)}}>
                    <ChevronLeftIcon sx={{ fontSize: 40 }}/>
                  </div> 
                </div>
                <div style={{width:"50%", justifyContent:"center", display:"flex"}}>
                  <div style={{fontSize:22, borderBottom: '1px solid #ced4da',width:"50%"}}>
                    <h4 style={{textAlign:"center"}}>{data.first_name+" "+data.last_name}</h4>
                  </div>
                </div>
                <div style={{width:"25%", justifyContent:"flex-end", display:"flex"}}>
                  <p onClick={()=>{setReferencePhotos(true)}}>{"Show reference photos"}</p>
                </div>
              </div>
                <List style={{maxHeight: '90%', overflow: 'auto', marginTop:-10}}>
                  <div className='quote-detail-content-container'>
                    {
                      data.image_url ?
                      data.image_url.map(res=>{
                        return(
                          <>
                            <div className='quote-detail-photos'>
                              <img
                                alt="image"
                                src={res}
                                style={{width:500,height:380}}
                              />
                            </div>
                          </>
                        )
                      })
                      :null
                    }
                    
                  </div>
                </List>
            </Paper>
          </Grid>
        </Grid>
      </div>   
    )
  }

  const referenceView = () =>{
    return(
      <div style={{width:side_navi? innerWidth-240:innerWidth-55 , marginLeft:side_navi? "240px":"55px"}}>
        <Grid  container alignItems='center' justifyContent='center'>
          <Grid item xs={11} style={{marginTop:30, marginLeft:20}}>
            <Paper
              style={{marginTop:20}}
              sx={{
              p:2,
              borderRadius:2,
              height: (innerHeight/1.3),
              width: "100%",
              backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
              <div style={{display:"flex", justifyContent:"center",width:"100%"}}>
                <div style={{ width:"3%",display:"flex", alignItems:"center" }}>
                  <div className="quote-detail-back" onClick={()=>{setReferencePhotos(false)}}>
                    <ChevronLeftIcon sx={{ fontSize: 40 }}/>
                  </div> 
                </div>
                <div style={{width:"50%", justifyContent:"center", display:"flex"}}>
                  <div style={{fontSize:22, borderBottom: '1px solid #ced4da',width:"35%"}}>
                    <h4 style={{textAlign:"center"}}>{data.first_name+" "+data.last_name}</h4>
                  </div>
                </div>
                <div style={{width:"50%", justifyContent:"center", display:"flex"}}>
                  <div style={{fontSize:22, borderBottom: '1px solid #ced4da', width:"35%"}}>
                    <div style={{flexDirection:"row", display:"flex",alignItems:"center", justifyContent:"center"}}>
                      <h4 style={{textAlign:"center"}}>{"Reference"}</h4>
                      <CloseIcon onClick={()=>{setReferencePhotos(false)}} sx={{ fontSize: 30, marginLeft:5, fill:"grey" }}/>
                    </div>
                  </div>
                </div>
              </div>
              <List style={{maxHeight: '84%', overflow: 'auto', marginTop:5,display:"flex", justifyContent:"center"}}>
                <div style={{ width:"3%"}}>
                </div>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={6}>
                    <div className='quote-detail-content-container'>
                    {
                      data.image_url ?
                      data.image_url.map(res=>{
                        return(
                          <>
                            <div className='quote-detail-photos'>
                              <img
                                alt="image"
                                src={res}
                                style={{width:side_navi? innerWidth/3:innerWidth/2.5,height:450}}
                              />
                            </div>
                          </>
                        )
                      })
                      :null
                    }
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className='quote-detail-content-container'>
                    {
                      reference_photos ?
                      reference_photos.map(res=>{
                        return(
                          <>
                            <div className='quote-detail-photos'>
                              <img
                                alt="image"
                                src={res}
                                style={{width:side_navi? innerWidth/3:innerWidth/2.5,height:450,border:'1px solid #ced4da'}}
                              />
                            </div>
                          </>
                        )
                      })
                      :null
                    }
                    </div>
                  </Grid>
                </Grid>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </div>   
    )
  }

  const refreshPage =(info)=>{
    checkInfo(info)
    updateInfo(info)
    setData(info)
    if(status===2){
      if(quoteDetails.status==="Booked"){
        setActiveStatus(3)
      }else{
        setActiveStatus(4)
      }
    }else{
      if(info.access_suitability && info.photo_check){
        setActiveStatus(1)
      }else{
        setActiveStatus(0)
      }
    }
    
  }

  return (
    <div className="quote-main">
      <Header title={"Quotes"} search={false} />
      <HeaderMenu status_id={status} title={status===2 ? "With patient":status===3 ? "Archived":"To do"} quote={history.location.quote_index} refresh={refreshPage}/>
      {
        updatedDetail && updatedDetail.action ?
        <QuoteModal data={quoteDetails} display={modalShow} update={updatedDetail} refresh={refreshPage} checkedItem={checkedItem}/>
        :
        null
      }
      { photoViewDisplay ? referencePhotos ? referenceView():photoView():detailView()}
         
    </div>
  );
}

export default Quote_detail;
