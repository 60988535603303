import { Col,Row } from "antd";
import "boxicons";
import { phone, google_login, apple_login, dots } from "../../assets/index";
import "./index.scss";

const Landing = (props) => {

  return (
    <>
      <Row align="middle">
      <Col xs={24} sm={24} md={24} lg={12} xl={14}>
        <div className="lan-left">
          <div>
            <div className="lan-title">
              <b>Dental Monitoring App and more</b>
            </div>
            <p className="lan-sub-title">Launching soon</p>
            <div className="lan-button">
              <p  className="try-now" href="/auth">Try now</p>
              <p  className="chat" href="/auth">Chat with us</p>
            </div>
            <div className="lan-dots-view">
              <img className="lan-dots" alt="login" src={dots} />
            </div>
            <div>
              <img className="lan-login-button" alt="login" src={apple_login} />
              <img className="lan-login-button" alt="login" src={google_login} />
            </div>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={10} className="lan-right">
        <div >
          <img
            alt="logo"
            src={phone}
            className="lan-image"
          />
        </div>
      </Col>
        
      </Row>
    </>
  );
};

export default Landing;
