import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';
import './index.scss'

const SearchInput = (props) => {
    const [value, setValue] = React.useState(null);

    const onChangeSearchText = (text) =>{
        setValue(text)
        props.search(text)
    }

    const search = () =>{
        props.search(value)
    }

    
    return (
        <>
            <div className='search-main'>
                <div className='serach-input'>
                    <TextField 
                        InputProps={{
                            disableUnderline:true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon onClick={()=>{search()}}/>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    {
                                        value && value.length>0?
                                        <CloseIcon 
                                            onClick={()=>{
                                                setValue("") 
                                                props.search("")
                                            }} 
                                            style={{ width:15, height:15 }}
                                        />
                                        :null
                                    }
                                </InputAdornment>
                            ),
                        }}
                        style={{paddingRight:20, paddingLeft:20}}
                        id="standard-basic"
                        placeholder={"Search name or quote number..."}
                        variant="standard" 
                        value={value}
                        onChange={(event) => onChangeSearchText(event.target.value)}
                        focused
                        sx={{
                            width: "100%",
                            backgroundColor:"white",
                            borderRadius:20,
                            borderWidth:1
                        }}
                    />
                </div>
                
            </div>
                
        </>
    )
}

export default SearchInput;