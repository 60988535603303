import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import { InputAdornment } from '@material-ui/core';
import './index.scss'
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import List from '@mui/material/List';
import { Link } from "react-router-dom"; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const HeaderMenu = (props) => {
    const history = useHistory();
    const [data, setData] = React.useState(history.location.data ? history.location.data:null);
    const [value, setValue] = React.useState(null);
    const [status, setStatus] = React.useState(props.status_id ? props.status_id:null);
    const [quoteIndex, setQuoteIndex] = React.useState(props.quote ? props.quote:props.quote===0 ? 0:null);
    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
    const side_navi = useSelector((state) => state.SideNaviBarReducer.side_bar_action);
    const item_list = useSelector((state) => props.status_id==1 ? state.QuoteReducer.quote_list.to_do_list:state.QuoteReducer.quote_list.review_list);
    const portal = useSelector((state) => state.AuthReducer.portal);

    const resize =()=> {
        setInnerWidth(window.innerWidth)
        setInnerHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener("resize", resize)
    }, []);

    const back = () =>{
        history.push("/"+portal+"/quote")
    }

    const nextAction = () =>{
        history.push({
            pathname: "./"+item_list[quoteIndex+1].id,
            data:item_list[quoteIndex+1],
            status: status,
            quote_index:quoteIndex+1
        })
        setData(item_list[quoteIndex+1])
        setQuoteIndex(quoteIndex+1)
        props.refresh(item_list[quoteIndex+1])
    }

    const previousAction = () =>{
        history.push({
            pathname: "./"+item_list[quoteIndex-1].id,
            data:item_list[quoteIndex-1],
            status: status,
            quote_index:quoteIndex-1
        })
        setData(item_list[quoteIndex-1])
        setQuoteIndex(quoteIndex-1)
        props.refresh(item_list[quoteIndex-1])
    }

    return (
        <>
            <div className='header-menu-main' style={{width:side_navi? innerWidth-240:innerWidth-55 , marginLeft:side_navi? "240px":"55px"}}>
                <div className='header-menu-bar'>
                    <div className='header-menu-container'>
                        <p onClick={()=>back()}>{props.title +" > "+data.id}</p>
                    </div>
                    <div style={{marginTop:70, marginRight:50}}>
                        {
                            item_list.length-1 > 0 ?
                                item_list.length-1 <= quoteIndex ?
                                <div className='header-menu-next' onClick={()=>previousAction()}>
                                    <ArrowBackIcon />
                                    <p>Previous</p>
                                </div>
                                :
                                quoteIndex >=1 && quoteIndex < item_list.length ?
                                <div style={{display:"flex"}}>
                                    <div className='header-menu-next' onClick={()=>previousAction()} style={{marginRight:10}}>
                                        <ArrowBackIcon />
                                        <p>Previous</p>
                                    </div>
                                    <div className='header-menu-next' onClick={()=>nextAction()}>
                                        <p>Next</p>
                                        <ArrowForwardIcon />
                                    </div>
                                </div>
                                :
                                <div className='header-menu-next' onClick={()=>nextAction()}>
                                    <p>Next</p>
                                    <ArrowForwardIcon />
                                </div>
                            :null
                        }
                    </div>
                </div>
            </div>
                
        </>
    )
}

export default HeaderMenu;