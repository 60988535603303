// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyuuamgJRrfG-6o6-r35i4It0WbbtJeHo",
  authDomain: "smile-engine.firebaseapp.com",
  databaseURL: "https://smile-engine-default-rtdb.firebaseio.com",
  projectId: "smile-engine",
  storageBucket: "smile-engine.appspot.com",
  messagingSenderId: "289108430555",
  appId: "1:289108430555:web:926e8aff8a1fdaba1346fe",
  measurementId: "G-N28GS2LBXM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);


export {db,auth,analytics};
