
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logo, complete_gif } from "../../assets/index";
import { useHistory } from "react-router-dom";
import "./index.scss";
//3rd party ui library
import { Alert, TextField } from '@mui/material';
import { Button, Col, Form, Modal, Row } from "antd";
import "boxicons";
import { portalAction } from "../../actions/auth";

const Auth = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [screen, setScreen] = useState(0);
  const [portal, setPortal] = useState(null);

  const loginScreen = (event) =>{
    setPortal(event.target.value)
  }

  const navigatetoPortalUrl = ()=>{
    if(portal && portal.length>0){
      localStorage.setItem("portal", portal) 
      dispatch(portalAction(portal))
      history.push("/"+portal+"/auth");
    }
  }

  return (
    <>
      <Row align="middle">
      <Col xs={24} sm={24} md={24} lg={12} xl={11} className="auth-left">
        <div>
          <img
            alt="logo"
            src={logo}
            className="auth-image"
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={13}>
        <div className="auth-right">
          <div>
            <img
              alt="logo"
              src={complete_gif}
              className="auth-gif-icon"
            />
            <p className="auth-sub-title">Company Portal</p>
            <div class="auth-portal-container">
              <TextField 
                id="standard-basic"
                label="Company portal" 
                variant="standard" 
                sx={{
                  width: 400
                }}
                value={portal}
                onChange={(event)=>{ loginScreen(event)}}
              />
              <div class="auth-side-content">
                <p >.smileengine.co</p>
              </div>
            </div>
            <div className="auth-button" onClick={()=>{ navigatetoPortalUrl()}}>
              <p className="auth-button-text" href="/auth">Continue</p>
            </div>
          </div>
          
        </div>
      </Col>
      
        
      </Row>
    </>
  );
};

export default Auth;
