import React, { useState } from "react";
import Header from '../../components/Header/index'

// import LoginForm from "../../components/auth/LoginForm";
// import RegisterForm from "../../components/auth/RegisterForm";
// import ForgotForm from "../../components/auth/ForgotForm";
// import SubscriptionForm from "../../components/auth/SubscriptionForm";
import "./index.scss";

function Patient() {
  const [page, setPage] = useState("login");
  return (
    <div className="patient-main">
      <Header title={"Patients"}/>
    </div>
  );
}

export default Patient;
