import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const DropdownField = (props) => {
  const [reason, setReason] = React.useState(props.value ? props.value:null);
  const handleChange = (event) => {
    setReason(event.target.value);
    props.list(event.target.value)
  };
  return (
    <div>
      <FormControl sx={{ m: 1 }} variant="standard" style={{width:200, marginLeft:0}}>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={reason}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={"no_response"}>No response</MenuItem>
          <MenuItem value={"no_reason"}>No reason</MenuItem>
          <MenuItem value={"price"}>Price</MenuItem>
          <MenuItem value={"location"}>Location</MenuItem>
          <MenuItem value={"health_fund"}>Health fund</MenuItem>
          <MenuItem value={"decide_later"}>Decide later</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownField;
