import React from 'react'
import MenuBar from '../components/MenuBar/index'
import Footer from '../components/Footer/index'

import './index.css'

const Layout = (props) => {
    return(
        <React.Fragment>
            <MenuBar />

            <main className="main-content">
                {props.children}
            </main>

            {/* <Footer/> */}
        </React.Fragment>
    );
}

export default Layout;