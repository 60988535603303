import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";

import './index.css'
import SearchBar from '../../components/SearchInput/index'

const Header = (props) => {
    const history = useHistory();
    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
    const [userProfile, setUserProfile] = React.useState(window.innerWidth);
    const authPortal = useSelector((state) => state.AuthReducer.portal);
    const side_navi = useSelector((state) => state.SideNaviBarReducer.side_bar_action);
    const resize =()=> {
        setInnerWidth(window.innerWidth)
    }

    useEffect(() => {
        window.addEventListener("resize", resize)
        let user = localStorage.getItem("user");
        if(user){
            setUserProfile(JSON.parse(user))
        }
    }, []);

    const searchText = (text) =>{
        props.searchText(text)
    }

    const logout = () =>{
        localStorage.setItem("token", null);
        history.push("/"+authPortal+"/auth");
        window.location.reload();
    }

    return (
        <nav className="header-main" style={{width:side_navi? innerWidth-240:innerWidth-55 , marginLeft:side_navi? "240px":"55px"}}>
            <div className="header-nav-wrapper">
                <p className="header-title" href='/'>{props && props.title}</p>
                <ul className="header-menu">
                    <li className="header-chat"><a  className="header-link">Chat</a></li>           
                    <li className="header-profile"><a onClick={()=>logout()} className="header-link">{userProfile && userProfile.email ? userProfile.email.slice(0, 2).toUpperCase():null}</a></li>                     
                </ul>
            </div>
            
            {
                props && props.search ?
                <div className='header-search-container'>
                    <div className="header-search">
                        <SearchBar search={searchText}/>
                    </div>
                </div>
                
                :null
            }

        </nav>
    )
}

export default Header;