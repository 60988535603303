import React from 'react'
import './index.scss'
import Grid from '@mui/material/Grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { useHistory } from "react-router-dom";
import FolderDropdown from '../FolderDropdown/index'
import moment from "moment";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ShareModal from '../ShareModal/index'
import { getQuotes} from "../../actions/quote";
import { useSelector, useDispatch } from 'react-redux'
import { updateQuote, deleteQuote } from "../../actions/quote";

const Table = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const quote_list = useSelector((state) => state.QuoteReducer.quote_list);

    const [title, setTitle] = React.useState(
        props.list && props.list==1 ? 
        ["Name","Quote number","Status","Created on"]
        :
        ["Name","Quote number","Status","Expiry","Indicative price"]
    );
    const [deleteComponent, setShowDeleteComponent] = React.useState()
    const [deleteSelectedItem, setDeleteSelectedItem] = React.useState(null)
    const [reopenModal, setReopenModal] = React.useState(false)
    const [reopenItem, setReopenItem] = React.useState(false)
    // const [archiveModal, setArchiveModal] = React.useState(false)

    const deleteItem = (item,index) =>{
        setShowDeleteComponent(true)
        setDeleteSelectedItem(index)
    }

    const confirmDelete = (item,index) =>{
        setDeleteSelectedItem(null)
        dispatch(deleteQuote(item.id))
    }

    const modalShow = (action) =>{
        setReopenModal(action)
    }

    const toDetailPage = (list,index) =>{
        if(props.list!= 3){
            history.push({
                pathname: "./quote/"+list.id,
                data:list,
                status: props.list,
                quote_index:index
            })
        }
    }

    const tableContent = (data) =>{
        return(
            data.map((list,index)=>{
                return(
                    <Grid container item spacing={0.6} style={{marginTop:10,backgroundColor:"white", height:50, borderRadius:10, paddingRight:10}}>
                        <React.Fragment>
                            <Grid onClick={()=>{ toDetailPage(list,index) }} item xs={props.list=== 2 || props.list=== 3 ? 2.1:2.5}>
                                <p style={{ marginTop:0}}>{list.first_name + " " + list.last_name}</p>
                            </Grid>
                            <Grid onClick={()=>{ toDetailPage(list,index) }} item xs={props.list=== 2 || props.list=== 3 ? 2.1:2.5}>
                                <p style={{marginTop:0, marginLeft:7}}>{list.id}</p>
                            </Grid>
                            <Grid onClick={()=>{ toDetailPage(list,index) }} item xs={props.list=== 2 || props.list=== 3 ? 2.1:2.5}>
                                <div style={{ marginLeft:14}} className="table-status-container">
                                    <FiberManualRecordIcon 
                                        style={{ 
                                            width:15, 
                                            height:15,
                                            marginTop:5,
                                            fill: 
                                                list.status==="Reopen" ? 
                                                '#FFDE59'
                                                :list.status==="In Progress" || list.status==="Quote Sent" || list.status==="Booked" ? 
                                                "#1DCD8D"
                                                :list.status==="Archived" ? 
                                                "grey"
                                                :"red" 
                                        }}
                                    />
                                    <p style={{ 
                                        marginTop:0,
                                        marginLeft:10,  
                                        color:  
                                            list.status==="Reopen" ? 
                                            '#FFDE59'
                                            :list.status==="In Progress" || list.status==="Quote Sent" || list.status==="Booked"? 
                                            "#1DCD8D"
                                            :list.status==="Archived" ? 
                                            "grey"
                                            :"red" 
                                    }}>
                                        {list.status}
                                    </p>
                                </div>
                            </Grid>
                            <Grid onClick={()=>{ toDetailPage(list,index) }} item xs={props.list=== 2 || props.list=== 3 ? 2.1:2.5}>
                                <p style={{ marginTop:0, marginLeft: title.length==5 ? 18:21}}>{title.length==5 && list.indicative ? moment(list.indicative.expiry_date).format("DD-MM-YYYY") :moment(list.created_on).format("DD-MM-YYYY")}</p>
                            </Grid>
                            {
                                props.list=== 2 || props.list=== 3 ?
                                <Grid onClick={()=>{ toDetailPage(list,index) }} item xs={2.1} >
                                    <p style={{ marginTop:0, marginLeft:21}}>{list.indicative ? "$ "+list.indicative.indicative_price:"-"}</p>
                                </Grid>
                                :null
                            }
                            <Grid item xs={props.list=== 2 || props.list=== 3 ? 1.5:2} style={{ display:"flex",justifyContent:"flex-end"}}>
                                {
                                    index===deleteSelectedItem ?
                                    <div className='table-delete-container-main'>
                                        <div onClick={()=>confirmDelete(list)} style={{backgroundColor:"red"}} className="table-delete-container">
                                            <p style={{fontSize:14}}>Delete</p>
                                        </div>
                                        <div onClick={()=>setDeleteSelectedItem(null)} style={{backgroundColor:"#878B8B"}} className="table-delete-container">
                                            <p style={{fontSize:14}}>Cancel</p>
                                        </div>
                                    </div>
                                    :
                                    title.length==5 ?
                                        props.list=== 3 ?
                                        <div onClick={()=>{
                                            modalShow(true) 
                                            setReopenItem(index)
                                        }} style={{backgroundColor:"#F94D85"}} className="table-delete-container">
                                            <p style={{fontSize:14}}>Reopen</p>
                                        </div>
                                        :
                                        <FolderDropdown display={(action)=>{
                                            submitUpdates(list,action)
                                        }} />
                                    :
                                    <DeleteOutlineIcon onClick={()=>{deleteItem(list,index)}}/>
                                    
                                }
                                
                            </Grid>
                            {
                                reopenModal ?
                                <ShareModal reopen={"Reopen " +quote_list.archive_list[reopenItem].id} update={list} display={modalShow} data={{...list,suitability:false,capture_outcome:{...list.capture_outcome,archive_reason:null}}}/>
                                :
                                null
                            }
                        </React.Fragment>
                    </Grid>
                )
            })
        )
    }

    const submitUpdates =async(list,action)=>{
        dispatch(updateQuote({...list,capture_outcome:{...list.capture_outcome,archive_reason:action}},"Archived"))
        let updates = await dispatch(getQuotes())
        if(updates){
          props.refresh()
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid container item spacing={3}>
                <React.Fragment>
                    {
                        title.map(titleList=>{
                            return(
                                <Grid item xs={title.length==5  ? 2.1:2.5}>
                                    <p style={{fontSize:13}}>{titleList}</p>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={title.length==5  ? 1.5:2}>
                    </Grid>
                </React.Fragment>
                </Grid>
                {
                    props.list === 1 ?
                    tableContent(quote_list.to_do_list)
                    :
                    props.list === 2 ?
                    tableContent(quote_list.review_list)
                    :
                    props.list === 3 ?
                    tableContent(quote_list.archive_list)
                    :null
                }
                
            </Grid>
            
        </>
    )
}

export default Table;