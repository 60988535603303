import React from 'react'
import SideNaviBar from '../components/SideNaviBar/index'
import Footer from '../components/Footer/index'
import Header from '../components/Header/index'

// import './index.css'

const Login_layout = (props) => {
    // const route = props.children.props.children
    // let checkQuote = route.find(res=>{
    //     if(res.props.path.includes("quote")){
    //         return true
    //     }else{
    //         return false
    //     }
    // })
    

    return(
        <React.Fragment>
            <SideNaviBar />

            <main className="main-content">
                {props.children}
            </main>

            {/* <Footer/> */}
        </React.Fragment>
    );
}

export default Login_layout;