import logo from './logo.svg';
import './App.css';
import { getApp } from "./utils/helpers";
import { BrowserRouter } from "react-router-dom";

function App() {
  const CurrentApp = getApp();

  return (
    <div className="App">
      <BrowserRouter>
        <CurrentApp />
      </BrowserRouter>
    </div>
  );
}

export default App;
