import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import { useSelector } from 'react-redux'

const steps = [ 
  "Request assessment", "Complete quote"
];
const followUpSteps = [ 
  "Request assessment", "Complete quote", "Follow up"
];

const ProgressStep = (props) => {
  const quote_list = useSelector((state) => props.status_id==1 ? state.QuoteReducer.quote_list.to_do_list:state.QuoteReducer.quote_list.review_list);
  const [quoteIndex, setQuoteIndex] = React.useState(props.quote ? props.quote:props.quote===0 ? 0:null);

  const [activeStep, setActiveStep] = React.useState(
    props.active==4 ?
      2
    : props.active
  );
  
  const handleStep = (step) => {
    props.status(step)
  };
  return (
      <Box sx={{ width: '100%' }}>
      <Stepper orientation="vertical" activeStep={quote_list[quoteIndex] && quote_list[quoteIndex].photo_check && quote_list[quoteIndex].access_suitability ? activeStep:0}>
        {
          props.active===3 || props.active===4 ?
          followUpSteps.map((label, index) => {
            return (
              <Step 
                key={label}
                sx={{
                  ".MuiStepConnector-root": {
                    top: 0,
                    backgroundColor:"#E8FFF7",
                  },
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#1DCD8D",
                  },
                  ".MuiSvgIcon-root:not(.Mui-completed)": {
                    color: "white",
                    border: "2.5px solid #1DCD8D",
                    borderRadius: "50%",
                    height:25,

                  },
                  ".MuiStepIcon-text": {
                    fill: "black",
                    fontWeight: 600,
                  },
                  "& .Mui-active": {
                    backgroundColor:"#E8FFF7",
                    height:40,
                    alignItems:"center",
                    display:"flex"
                  },
                  ".MuiSvgIcon-root": {
                    backgroundColor:"#E8FFF7"
                  }
                  }}>
                {/* <StepButton completed={false} onClick={() => {handleStep(index)}}> */}
                  <StepLabel>{label}</StepLabel>
                {/* </StepButton> */}
              </Step>
            );
          })
          :
          steps.map((label, index) => {
            return (
              <Step 
                key={label}
                sx={{
                  ".MuiStepConnector-root": {
                    top: 0,
                    backgroundColor:"#E8FFF7",
                  },
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#1DCD8D",
                  },
                  ".MuiSvgIcon-root:not(.Mui-completed)": {
                    color: "white",
                    border: "2.5px solid #1DCD8D",
                    borderRadius: "50%",
                    height:25,

                  },
                  ".MuiStepIcon-text": {
                    fill: "black",
                    fontWeight: 600,
                  },
                  "& .Mui-active": {
                    backgroundColor:"#E8FFF7",
                    height:40,
                    alignItems:"center",
                    display:"flex"
                  },
                  ".MuiSvgIcon-root": {
                    backgroundColor:"#E8FFF7"
                  }
                }}>
                <StepButton completed={false} onClick={() => {handleStep(index)}}>
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            );
          })
        }
      </Stepper>
    </Box>
  );
}

export default ProgressStep;