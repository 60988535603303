import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux'
import { updateQuote, getQuotes } from "../../actions/quote";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ShareModal = (props) => {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const submitUpdates =async()=>{
    let updates = await dispatch(updateQuote(props.data,"Reopen"))
    if(updates){
      props.display(false) 
    }
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">{props.reopen}</h2>
          {/* <p id="parent-modal-description">
            {props.reopen}
          </p> */}
          <div>
            <Button onClick={()=>{submitUpdates()}}>Yes</Button>
            <Button onClick={()=>{props.display(false)}}>No</Button>
          </div>
          
        </Box>
      </Modal>
    </div>
  );
}
export default ShareModal;