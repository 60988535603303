import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux'
import { updateQuote, firebasePushNotification } from "../../actions/quote";
import { useSelector } from 'react-redux'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const QuoteModal = (props) => {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const submitUpdates = async() =>{
    
    let updates = await dispatch(
      updateQuote(
        props.data, 
        props.update.type==="publish" ? 
        props.data.capture_outcome && props.data.capture_outcome.archive_reason ?
          "Archived"
          :
          "Quote Sent"
        :props.update.type==="notSuit"? 
          "Archived"
        : props.update.type==="update" ?
            props.data.status==="Quote Sent" ? 
            props.data.capture_outcome && props.data.capture_outcome.archive_reason ?
              "Archived"
              :
              "Quote Sent"
            :"In Progress"
        : "In Progress"
      )
    )
    if(updates){
      props.refresh(props.data)
      props.display({action:false,type:props.update.type}) 
      dispatch(firebasePushNotification(props.data))
    }
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">{props.update.type==="publish" ? "Publish quote":props.update.type==="notSuit"? "Patient not suitable":"Update quote"}</h2>
          <p id="parent-modal-description">
            Do you want to save the changes?
          </p>
          <div>
            <Button 
              onClick={()=>{
                submitUpdates()
              }}
            >
              Yes
            </Button>
            <Button onClick={()=>{
              props.display({action:false,type:props.update.type,check:{item:props.checkedItem.item,check:!props.checkedItem.check}})
            }}>No</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
export default QuoteModal;