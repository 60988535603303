const initialState = {
    portal:null,
  };
  
  function AuthReducer(state = initialState, { type, payload }) {
    switch (type) {
      case "auth/portal":
        return {
          ...state,
          portal: payload,
        };
      default:
        return state;
    }
  }
  
  export default AuthReducer;
  