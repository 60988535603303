
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logo, complete_gif } from "../../assets/index";
import { useHistory } from "react-router-dom";
import { signIn, resetPass } from "../../actions/auth";

//component
import TextInput from "../../components/TextInput/index";

import "./index.scss";

//3rd party ui library
// import Alert from '@mui/material/Alert';
import {  Col, Form, Row } from "antd";
import "boxicons";

//icon
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authPortal = useSelector((state) => state.AuthReducer.portal);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [resetPasswordView, setResetPasswordView] = React.useState(false);

  const login = async() =>{
    let loginRslt = await dispatch(signIn(email,password))
    if(!loginRslt.msg){
      localStorage.setItem("token", JSON.stringify(loginRslt));
      history.push("/"+authPortal+"/dashboard");
      window.location.reload();
    }else{
      setError(loginRslt.msg)
    }
  }

  const resetPassword =async()=>{
    setResetPasswordView(true)
    // let resetRslt = await dispatch(resetPass("hoongyaen1119@icloud.com"))
    // if(!resetRslt.msg){
    //   setMessage("Reset email sent! Please check your mailbox.")
    // }else{
    //   setError(resetRslt.msg)
    // }
  }
  
  const reset = async() =>{
    let resetRslt = await dispatch(resetPass(email))
    if(!resetRslt.msg){
      setError(null)
      setMessage("Reset email sent! Please check your mailbox.")
      setResetPasswordView(false)
    }else{
      setError(resetRslt.msg)
    }
  }

  return (
    <>
      <Row align="middle">
      <Col xs={24} sm={24} md={24} lg={12} xl={11} className="auth-left">
        <div>
          <img
            alt="logo"
            src={logo}
            className="auth-image"
          />
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={13}>
        {
          resetPasswordView ?
          <div className="auth-right">
            <div>
              <img
                alt="logo"
                src={complete_gif}
                className="auth-gif-icon"
              />
              <p className="auth-sub-title">Practice Log In</p>
              <div>
                <TextInput 
                  label="Email" 
                  icon={<PersonIcon style={{ fill: '#C6C6C6' }}/>}
                  value={email}
                  onChange={(event)=>{ setEmail(event.target.value) }}
                />
              </div>
              
              <div className="auth-button" onClick={()=>{reset()}}>
                <p  className="auth-button-text" href="/auth">Reset</p>
              </div>
            </div>
          </div>
          :
          <div className="auth-right">
            <div>
              <img
                alt="logo"
                src={complete_gif}
                className="auth-gif-icon"
              />
              <p className="auth-sub-title">Practice Log In</p>
              <div>
                <TextInput 
                  label="Email" 
                  icon={<PersonIcon style={{ fill: '#C6C6C6' }}/>}
                  value={email}
                  onChange={(event)=>{ setEmail(event.target.value) }}
                />
              </div>
              <div>
                <TextInput 
                  label="Password" 
                  icon={<VpnKeyIcon style={{ fill: '#C6C6C6' }}/>}
                  value={password}
                  onChange={(event)=>{ setPassword(event.target.value) }}
                />
              </div>
              <p onClick={()=>resetPassword()} className="auth-regular-text">Forgot password?</p>
              {
                message ?
                <p style={{color:"#1DCD8D"}}>{message}</p>
                :
                error ?
                <p style={{color:"red"}}>{error}</p>
                :null
              }
              <div className="auth-button" onClick={()=>{login()}}>
                <p  className="auth-button-text" href="/auth">Login</p>
              </div>
            </div>
          </div>
        }
        
      </Col>
      </Row>
      
    </>
  );
};

export default Login;
