import React, { useState, useEffect } from "react";
import logo from '../../logo.svg';
import Header from '../../components/Header/index'
import "./index.scss";
import { getQuotes } from "../../actions/quote";
import { useSelector, useDispatch } from 'react-redux'

// import LoginForm from "../../components/auth/LoginForm";
// import RegisterForm from "../../components/auth/RegisterForm";
// import ForgotForm from "../../components/auth/ForgotForm";
// import SubscriptionForm from "../../components/auth/SubscriptionForm";

const Dashboard = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQuotes())
  }, []);

  return (
    <div className="dashboard-main">
      <Header title={"Dashboard"}/>
    </div>
  );
}

export default Dashboard;
