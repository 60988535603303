import React from 'react'

import './index.css'

const MenuBar = () => {
    return (
        <nav className="header">
            <div className="nav-wrapper">
                <a className="logo" href='/'></a>
                <input className="menu-btn" type="checkbox" id="menu-btn"/>
                <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>

                <ul className="menu">
                    <li className="menu-item"><a href="/patient">For Patient</a></li>
                    <li className="menu-item"><a href="/dentist">For Dentist</a></li>
                    <li className="login-item"><a  className="login-item-link" href="/auth">Clinic Log In</a></li>                
                </ul>
                
            </div>
        </nav>
    )
}

export default MenuBar;